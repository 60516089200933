// noinspection JSUnusedGlobalSymbols

import {
    ProductPageTabs as SecureProductPageTabs,
} from 'SecureRoute/ProductPage/ProductPage.config';

export {
    DEFAULT_POSITION,
    ProductPageTabs,
    RENDER_REVIEW_OPTIONS,
} from 'SecureRoute/ProductPage/ProductPage.config';

export enum WitradisProductPageTabs {
    PRICE_DETAILS = 'price_details',
}

export type ExtendedProductPageTags = SecureProductPageTabs | WitradisProductPageTabs;
