// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import { ReactElement } from 'react';

import {
    ProductPageComponent as BaseProductPageComponent,
} from 'BaseRoute/ProductPage/ProductPage.component';

export {
    ProductReviews,
} from 'BaseRoute/ProductPage/ProductPage.component';

/** @namespace Lebonvin/PwaSecure/Route/ProductPage/Component */
export class ProductPageComponent extends BaseProductPageComponent {
    // Override to remove related products and upsell products.
    renderAdditionalSections(): ReactElement {
        return (
            <>
                { this.renderProductTabs() }
            </>
        );
    }
}

export default ProductPageComponent;
