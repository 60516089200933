/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import {
    DEFAULT_POSITION,
    ProductPageTabs,
} from 'Route/ProductPage/ProductPage.config';
import type { ProductPageTab } from 'Route/ProductPage/ProductPage.type';
import { RENDER_REVIEWS } from 'Src/theme.config';

// Override for the ProductPage tab map, to enable extensible behaviours such as
// forcing reviews to be disabled.

const tabMap = (
    originalTabMap: Record<ProductPageTabs, ProductPageTab>,
): Record<ProductPageTabs, ProductPageTab> => {
    const {
        [ ProductPageTabs.INFORMATION ]: modifiedInformation,
        [ ProductPageTabs.REVIEWS ]: modifiedReviews,
        ...retainedTabMap
    } = originalTabMap;

    return {
        ...retainedTabMap,
        [ ProductPageTabs.INFORMATION ]: {
            ...modifiedInformation,
            name: __('Producer'),
            position: DEFAULT_POSITION * 2,
        },
        [ ProductPageTabs.REVIEWS ]: {
            ...modifiedReviews,
            shouldTabRender: (): boolean => RENDER_REVIEWS && modifiedReviews.shouldTabRender(),
            position: DEFAULT_POSITION * 2,
        },
    };
};

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap,
        },
    },
};
