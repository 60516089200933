// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import { ReactNode } from 'react';

import { ProductTabShape } from 'Component/ProductTabs/ProductTabs.type';
import {DEFAULT_POSITION, RENDER_REVIEW_OPTIONS} from 'Route/ProductPage/ProductPage.config';
import {ProductPageTab} from 'Route/ProductPage/ProductPage.type';
import {
    ProductPageComponent as SourceProductPageComponent,
} from 'SourceRoute/ProductPage/ProductPage.component';

export {
    ProductReviews,
    ProductTabs,
    ProductAttributes,
    ProductReviewForm,
    ProductLinks,
    ProductInformation,
    Popup,
    ProductActions,
} from 'SourceRoute/ProductPage/ProductPage.component';

/** @namespace Lebonvin/PwaBase/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    // Override the reviews tab to allow global reviews disable.
    renderProductReviewsTab(key: string): ReactNode {
        if (!RENDER_REVIEW_OPTIONS) {
            return null;
        }

        return super.renderProductReviewsTab(key);
    }

    // Override the reviews popup to allow global reviews disable.
    renderReviewPopup(): ReactNode {
        if (!RENDER_REVIEW_OPTIONS) {
            return null;
        }

        return super.renderReviewPopup();
    }

    // Override to extend the tabs rendering filter to include sorting.
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    shouldTabsRender(): ProductTabShape[] {
        return super.shouldTabsRender()
            .sort(
                (a: ProductPageTab, b: ProductPageTab) => {
                    const aPos = a?.position ?? DEFAULT_POSITION;
                    const bPos = b?.position ?? DEFAULT_POSITION;

                    return aPos - bPos;
                },
            );
    }
}

export default ProductPageComponent;
