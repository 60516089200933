/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import ProductPageComponent from 'Route/ProductPage/ProductPage.component';
import {
    DEFAULT_POSITION,
    ExtendedProductPageTags,
    ProductPageTabs,
    WitradisProductPageTabs,
} from 'Route/ProductPage/ProductPage.config';
import { ProductPageTab } from 'Route/ProductPage/ProductPage.type';

// Override to add an extra tab to the product page, for the Witradis specific
// price details.

const tabMap = (
    originalTabMap: Record<ProductPageTabs, ProductPageTab>,
    instance: ProductPageComponent, // Note this is actually a proxy because plugins
): Record<ExtendedProductPageTags, ProductPageTab> => ({
    ...originalTabMap,
    [ WitradisProductPageTabs.PRICE_DETAILS ]: {
        name: __('Pricing'),
        // eslint-disable-next-line arrow-body-style
        shouldTabRender: () => {
            // Ignoring type error about specific element not existing.
            // @ts-ignore
            const { dataSource: { price_details } } = instance.props;

            return !!price_details;
        },
        render: (key: string) => instance.renderProductPriceDetailsTab(key),
        position: DEFAULT_POSITION,
    },
});

export default {
    'Route/ProductPage/Component': {
        'member-property': {
            tabMap,
        },
    },
};
