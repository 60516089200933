// noinspection JSValidateJSDoc

import {
    Fragment, Key, PureComponent, ReactNode,
} from 'react';

import ExpandableContent from 'Component/ExpandableContent';
import {
    PriceDetails,
    PriceDetailsGroup,
    PriceDetailsItem,
    ProductPriceDetailsProps,
} from 'Component/ProductPriceDetails/ProductPriceDetails.type';
import {generatePriceDetailsLayout} from 'Component/ProductPriceDetails/ProductPriceDetails.util';

import './ProductPriceDetails.style';

/** @namespace Lebonvin/PwaWitradis/Component/ProductPriceDetails/Component */
export class ProductPriceDetailsComponent<
    P extends ProductPriceDetailsProps,
> extends PureComponent<P> {
    renderItemDetails(element: PriceDetailsItem) {
        if (element.value === null) {
            return null;
        }

        if (element.value === undefined) {
            if (element.modifiers === undefined || element.modifiers.length === 0) {
                return null;
            }
        }

        const rendered: ReactNode[] = [];
        const tableMods: {[s: string]: boolean} = {
            total: false,
            rate: false,
            discount: false,
        };

        if (element.modifiers !== undefined) {
            element.modifiers.forEach((mod) => {
                if (mod === 'blank') {
                    rendered.push(
                        <span
                          key={ `${ element.label }-blank` }
                          block="ProductPriceDetails"
                          elem="Blank"
                        >
                            &nbsp;
                        </span>,
                    );

                    return;
                }

                tableMods.total = (mod === 'total') ? true : tableMods.total;
                tableMods.rate = (mod === 'rate') ? true : tableMods.rate;
                tableMods.discount = (mod === 'discount') ? true : tableMods.discount;
            });
        }

        if (rendered.length === 0) {
            rendered.push(
                <span
                  key={ `${ element.label }-value` }
                  block="ProductPriceDetails"
                  elem="Value"
                  mods={ tableMods }
                >
                    { element.value }
                </span>,
            );
        }

        const displayLabel = element.value === undefined ? '' : element.label;

        return (
            <Fragment key={ element.label }>
                <dt
                  block="ProductPriceDetails"
                  elem="AttributeLabel"
                  mods={ tableMods }
                >
                    { displayLabel }
                </dt>
                <dd
                  block="ProductPriceDetails"
                  elem="ValueLabel"
                  mods={ tableMods }
                >
                    { rendered }
                </dd>
                <dd
                  block="ProductPriceDetails"
                  elem="ExtraLabel"
                  mods={ tableMods }
                >
                    { element.extra }
                </dd>
            </Fragment>
        );
    }

    renderElements(title: string, elements?: PriceDetailsItem[], modifiers?: string[]) {
        if (!elements) {
            return null;
        }

        return (
            <dl
              block="ProductPriceDetails"
              elem="Attributes"
              mods={ modifiers?.reduce((
                  result: {[x: string]: boolean},
                  mod,
              ) => (
                  {...result, [mod]: true}
              ), {}) }
              key={ title }
            >
                { elements.map(this.renderItemDetails) }
            </dl>
        );
    }

    renderGroups(groups: PriceDetailsGroup[]): ReactNode[] {
        return (
            groups.map(
                (group) => {
                    if (group.elements === undefined) {
                        return null;
                    }

                    return (
                        <div
                          block="ProductPriceDetails"
                          elem="AttributeBlock"
                          key={ `${group.title}-section` }
                          mods={ group.modifiers?.reduce((
                              result: {[x: string]: boolean},
                              mod,
                          ) => (
                              {...result, [mod]: true}
                          ), {}) }
                          // className={ group.modifiers ? group.modifiers?.join(' ') : '' }
                        >
                            <h2
                              block="ProductPriceDetails"
                              elem="Group"
                              key={ `${group.title}-title` }
                            >
                                { group.title }
                            </h2>
                            { this.renderElements(group.title, group.elements, group.modifiers) }
                        </div>
                    );
                },
            )
        );
    }

    renderExpandableContentWrapper(
        key: Key | undefined | null,
        heading: string,
        groups: PriceDetailsGroup[],
    ): ReactNode {
        return (
            <ExpandableContent
              // show placeholder if the details are not loaded?
              heading={ heading }
              mix={ { block: 'ProductAttributes', elem: 'Content' } }
              key={ key }
            >
                { this.renderGroups(groups) }
            </ExpandableContent>
        );
    }

    render(): ReactNode {
        const {areDetailsLoaded, key, product}: ProductPriceDetailsProps = this.props;
        // @ts-ignore
        const {price_details, rrp}: {
            price_details: PriceDetails | null;
            rrp: number;
        } = product;

        if (!areDetailsLoaded || !price_details) {
            return null;
        }
        const layoutDetails: PriceDetailsGroup[] = generatePriceDetailsLayout(price_details, rrp);

        return this.renderExpandableContentWrapper(key, 'Pricing', layoutDetails);
    }
}
