// noinspection JSUnusedGlobalSymbols, JSValidateJSDoc

import {ReactElement, Suspense } from 'react';

import Loader from 'Component/Loader';
import {
    ProductPriceDetails,
} from 'Component/ProductPriceDetails';
import {
    ProductPageComponent as SecureProductPageComponent,
} from 'SecureRoute/ProductPage/ProductPage.component';

export {
    ProductReviews,
} from 'SecureRoute/ProductPage/ProductPage.component';

/** @namespace Lebonvin/PwaWitradis/Route/ProductPage/Component */
export class ProductPageComponent extends SecureProductPageComponent {
    // ADDITION: Creating the price details tab.
    renderProductPriceDetailsTab(key: string): ReactElement {
        const {
            dataSource,
            areDetailsLoaded,
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> } key={ key }>
                 <ProductPriceDetails
                   product={ dataSource }
                   areDetailsLoaded={ areDetailsLoaded }
                   key={ key }
                 />
            </Suspense>
        );
    }
}

// Ignore TS2528 (duplicate default exports) - false positive from the IDE?! And
// apparently I need to ignore it on two different lines?! Not replicated in
// build warnings or similar.

// @ts-ignore
export default ProductPageComponent;
// @ts-ignore
