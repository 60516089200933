// noinspection JSValidateJSDoc

import {PriceDetails, PriceDetailsGroup} from 'Component/ProductPriceDetails/ProductPriceDetails.type';

/** @namespace Lebonvin/PwaWitradis/Component/ProductPriceDetails/Util/generatePriceDetailsLayout */
export function generatePriceDetailsLayout(price_details: PriceDetails, rrp: number): PriceDetailsGroup[] {
    const {
        unit_price,
        admin_fee,
        euros,
        pounds,
        exchange_rate,
        transport,
        duty,
        sticker,
        final,
        discount,
        discount_admin_fee,
        discount_euros,
        discount_pounds,
        discount_final,
        discount_start,
        discount_end,
    } = price_details;

    const exchangePrecision = 6;
    const euroPrecision = 4;
    const poundPrecision = 2;
    const exVatDivider = 1.2;
    const vatAmountDivider = 6;

    const dataPromo = {
        title: 'Promotion',
        elements: [
            { label: 'Cost Price', value: `€${ unit_price.toFixed(euroPrecision) }` },
            {
                label: 'Discount',
                value: `-€${ discount.toFixed(euroPrecision) }`,
                modifiers: ['discount'],
            },
            { label: 'Admin Fee (5%)', value: `€${ discount_admin_fee.toFixed(euroPrecision) }` },
            {
                label: 'Base Price',
                value: `€${ discount_euros.toFixed(euroPrecision) }`,
                modifiers: ['total'],
            },
            { label: 'euro-pounds-gap', modifiers: ['blank'] },
            {
                label: 'Price in Sterling',
                value: `£${ discount_pounds.toFixed(poundPrecision) }`,
                extra: `(1 EUR = ${exchange_rate.toFixed(exchangePrecision)} GBP)`,
                modifiers: ['rate'],
            },
            { label: 'Transport to UK', value: `£${ transport.toFixed(poundPrecision) }` },
            { label: 'Duty', value: `£${ duty.toFixed(poundPrecision) }` },
            {
                label: 'Duty Sticker',
                value: sticker > 0 ? `£${ sticker.toFixed(poundPrecision) }` : null,
            },
            {
                label: 'Total Price',
                value: `£${ discount_final.toFixed(poundPrecision) }`,
                modifiers: ['total'],
            },
            { label: 'details-end', modifiers: ['blank'] },
        ],
    };

    const isoDateStartLength = 10;
    const dataPromoDates = {
        title: 'Promotion Dates',
        elements: [
            { label: 'Starts', value: (discount_start || '').slice(0, isoDateStartLength) },
            { label: 'Ends', value: (discount_end || '').slice(0, isoDateStartLength) },
            { label: 'promo-dates-end', modifiers: ['blank'] },
        ],
        modifiers: ['promo-dates'],
    };

    return [
        {
            title: 'Price Details',
            elements: [
                { label: 'Cost Price', value: `€${ unit_price.toFixed(euroPrecision) }` },
                { label: 'discount-gap', modifiers: [discount > 0 ? 'blank' : ''] },
                { label: 'Admin Fee (5%)', value: `€${ admin_fee.toFixed(euroPrecision) }` },
                {
                    label: 'Base Price',
                    value: `€${ euros.toFixed(euroPrecision) }`,
                    modifiers: ['total'],
                },
                { label: 'euro-pounds-gap', modifiers: ['blank'] },
                {
                    label: 'Price in Sterling',
                    value: `£${ pounds.toFixed(poundPrecision) }`,
                    extra: `(1 EUR = ${exchange_rate.toFixed(exchangePrecision)} GBP)`,
                    modifiers: ['rate'],
                },
                { label: 'Transport to UK', value: `£${ transport.toFixed(poundPrecision) }` },
                { label: 'Duty', value: `£${ duty.toFixed(poundPrecision) }` },
                {
                    label: 'Duty Sticker',
                    value: sticker > 0 ? `£${ sticker.toFixed(poundPrecision) }` : null,
                },
                {
                    label: 'Total Price',
                    value: `£${ final.toFixed(poundPrecision) }`,
                    modifiers: ['total'],
                },
                { label: 'details-end', modifiers: ['blank'] },
            ],
            modifiers: [discount > 0 ? 'discounted' : ''],
        },
        {
            title: 'RRP',
            elements: [
                { label: 'ex VAT', value: `£${ (rrp / exVatDivider).toFixed(poundPrecision) }` },
                { label: 'VAT', value: `£${ (rrp / vatAmountDivider).toFixed(poundPrecision) }` },
                {
                    label: 'RRP inc VAT',
                    value: `£${ rrp.toFixed(poundPrecision) }`,
                    modifiers: ['total'],
                },
                { label: 'rrp-end', modifiers: ['blank'] },
            ],
        },
        discount > 0 ? dataPromo : { title: 'Promotion' },
        discount > 0 ? dataPromoDates : { title: 'Promotion Dates' },
    ];
}
